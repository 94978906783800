import { getOrderlineDetails } from '@backmarket/http-api/src/api-specs-my-orders-api/endpoints'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

import { SALES_CUSTOMER_CARE_SCOPE } from '@/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '@/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

export function useOrderlineDetails({
  // UUID or internal numerical ID
  orderlineId,
  options = { immediate: false },
}: {
  orderlineId: string
  options?: { immediate: boolean }
}) {
  const { logHttpOnEvent } = useSalesCustomerCareLogger()

  return useHttpFetch(getOrderlineDetails, {
    pathParams: {
      orderlineId,
    },
    ...options,
    onEvent: (event, context) =>
      logHttpOnEvent({
        event,
        context,
        errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.myOrders} [Orderline #${orderlineId}] Error when fetching orderline details`,
      }),
  })
}
